import React from 'react';
import Image from "gatsby-image";
import classes from './index.module.scss';
import { Link } from 'gatsby';
import IconButtons from "../Button/IconButtons"
export default function Feature({ data }) {
    const { headerSubtitle, headerTitleNode, headerDescription, body, buttonText, buttonUrl } = data;
    return (
        <div className={classes.root}>
            <div className={classes.keyfeatures}>{headerSubtitle}</div>
            <div className={classes.head}>
                <div className={classes.headtitle} dangerouslySetInnerHTML={{ __html: headerTitleNode.childMarkdownRemark.html }}></div>
                <p>{headerDescription}</p>
            </div>
            {body.map((item, index) => {
                return (
                    <div className={index % 2 === 0 ? classes.right : classes.left}>
                        <div className={classes.imgcontainer}>
                            <Image
                                fluid={item.itemImage.fluid}
                            />
                        </div>
                        <div className={classes.container}>
                            <div className={classes.containertitle} dangerouslySetInnerHTML={{ __html: item.itemTitleNode.childMarkdownRemark.html }}></div>
                            <div className={classes.containerdetail} dangerouslySetInnerHTML={{ __html: item.itemDetailsNode.childMarkdownRemark.html }}></div>
                            {/* <h2>Flexibility to <b>go “in and out” of products</b></h2>
                        <p>bolttech offers a wide range of protection product and service selection, ranging from switch to extended warranty for various electronic devices. We will help you handle all aspects of your selected product and service. We will service your customers’ repair or switch request with quality, like the way we are serving our 7.7 million customers worldwide. Our merchant portal will allow you the flexibility to go “in and out” of products in a click.</p> */}
                        </div>
                    </div>
                )

            })}
            <div className={classes.signup}>
                <Link to={buttonUrl}>
                    {/* <p className={classes.signup}>{buttonText}</p> */}
                    <IconButtons>{buttonText}</IconButtons>
                </Link>
            </div>
            
        </div>
    )
}