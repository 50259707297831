import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Feature from "../components/DatoFeaturePage";

export default function Blog(props) {
    const { data } = props;
    return (
      <Layout>
            <Feature data={data.datoCmsFeaturePage} />
        </Layout>

    );
}

export const query = graphql`
  query($id: String!) {
    datoCmsFeaturePage(id: {eq: $id}) {
        headerDescription
        headerSubtitle
        headerTitleNode {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
        body {
          itemImage {
            fluid(
                maxWidth: 360
                maxHeight: 550
              ) {
                ...GatsbyDatoCmsSizes
              }
          }
          itemTitleNode {
            childMarkdownRemark {
              html
            }
          }
          itemDetailsNode {
            childMarkdownRemark {
              html
            }
          }
          
        }
      }
  }
`;
